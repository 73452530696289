<template>
    <div class="field-table tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-full tw-text-base-content">
        <vue-good-table ref="table" :isLoading="loading" :columns="columns" :rows="filteredRowsData" :pagination-options="paginationOptions" :key="filteredRowsData">
            <template slot="table-actions">
                <button
                    v-if="Object.values(filters).length"
                    @click="clearFilters"
                    class="tw-bg-transparent tw-border tw-border-solid tw-rounded-2xl tw-px-2 tw-py-1 tw-mr-2 tw-cursor-pointer 
                    tw-border-brand tw-text-brand hover:tw-bg-brand hover:tw-text-white"
                >
                    RESET FILTER
                </button>
                <span v-else></span>
            </template>

            <template #column-filter="{ column }">
                <neo-datepicker v-if="column.field_type === 'date' && column.filterOptions && column.filterOptions.enabled" :range="true" v-model="filters[column.field]" :open-date="new Date()" placeholder="Select Date" format="DD-MMM-YYYY" class="tw-h-10 tw-rounded tw-w-full tw-overflow-hidden" ref="datePicker1" @input="updateColumnFilters()" @clear="clearDateRange(column)"> </neo-datepicker>

                <vue-multiselect ref="setFilterRef" v-else-if="column.filterOptions && column.filterOptions.enabled" placeholder="Select" v-model="filters[column.field]" :options="getFilterOptions(column)" @input="updateColumnFilters()" :multiple="false" :checkboxes="false" :limit="1" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :searchable="true" :show-labels="false" label="label" trackBy="name" class="tw-h-8 tw-w-full"> </vue-multiselect>
            </template>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field_type === 'date'">
                    <span v-if="props.row[props.column.field]">
                        {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }}
                    </span>
                    <span v-else> - </span>
                </div>
                <span v-else> {{ props.row[props.column.field] }} </span>
            </template>

            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
import Modal from "@/components/modal-content";
import dropdown from "@/components/dropdown-base";
import NeoInput from "@/components/input";
import Loader from "@/components/loader";
const VueMultiselect = () => import("@/components/vue-multiselect");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {DateTime} from "luxon";
import {getCreditUsage, getUserFullName} from "../services";
import {mapGetters} from "vuex";

export default {
    name: "credit-usage",
    title: "Credit Usage",
    components: {
        Modal,
        dropdown,
        NeoInput,
        Loader,
        "neo-datepicker": DatePicker,
        VueMultiselect,
    },
    data() {
        return {
            columns: [
                {
                    label: "Pack Name",
                    field: "name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Unique Id",
                    field: "display_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "User",
                    field: "user_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Date",
                    field: "updated_at",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Case Id",
                    field: "case_id",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Product Name",
                    field: "product_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Feature",
                    field: "credit_source_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Credit used",
                    field: "credits_used",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
            ],
            rows: [],
            loading: false,
            filteredRowsData: [],
            filters: {},
            paginationOptions: {
                enabled: true,
                mode: "records",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
            },
            userLists: [],
            search: "",
        };
    },
    async mounted() {
        this.loading = true;
        await this.fetchData();
        this.filteredRowsData = this.rows;
        this.loading = false;
    },
    computed: {
        ...mapGetters(["getAccountId", "getIsUserAdmin", "getUserAuthDetails"]),
        filteredUserLists() {
            return this.userLists.filter((el) => el.user_name.toLowerCase().includes(this.search.toLowerCase()));
        },
    },
    methods: {
        disabledRange(date) {
            return date > new Date();
        },

        clearDateRange(column) {
            this.filters[column.field] = [];
        },

        async fetchData() {
            this.rows = [];
            let qParams;
            if (this.getAccountId)
                qParams = {
                    tenant_id: this.getAccountId,
                };

            if (!this.getIsUserAdmin)
                qParams = {
                    ...qParams,
                    ...{user_id: this.getUserAuthDetails.userId},
                };
            const {data} = await getCreditUsage(qParams);
            this.rows = data.data.map((el) => ({
                ...el,
                ...{
                    alloted_credits: el.pack_credits - el.remaining_credits,
                    user_name: getUserFullName(el),
                },
            }));
            // this.pageData = data?.page;
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            dt = dt.substr(0, 10);
            return DateTime.fromISO(dt).toFormat(format);
        },

        getFilterOptions(column) {
            // get unique filter options array
            let filterData = [];
            filterData = this.rows
                .filter((row) => row[column.field])
                .map((row) => ({
                    name: column?.field_type == "date" ? this.formatDateFromISO(row[column.field], "dd MMM yyyy") : row[column.field],
                    label: row[column.field],
                }));
            if (filterData && filterData?.length > 0) {
                const uniqueMap = new Map();
                filterData = filterData.forEach((item) => {
                    uniqueMap.set(item.name, item);
                });
                return Array.from(uniqueMap.values());
            }
            return filterData;
        },
        updateColumnFilters() {
            let result = this.rows;
            for (let filter in this.filters) {
                let type = this.columns.find((col) => col.field === filter)?.field_type;
                if (type === "date" && this.filters[filter]?.length && this.filters[filter][0] && this.filters[filter][1]) {
                    result = result.filter((el) => {
                        const startDate = new Date(this.filters[filter][0]).setHours(0, 0, 0, 0);
                        const endDate = new Date(this.filters[filter][1]).setHours(23, 59, 59);
                        const date = new Date(el[filter]).getTime();
                        return startDate < date && date < endDate;
                    });
                } else if (type !== "date" && this.filters[filter]) {
                    result = result.filter((el) => {
                        return this.filters[filter].name === el[filter];
                    });
                }
            }
            this.filteredRowsData = result;
        },
        clearFilters() {
            this.filters = {};
            this.filteredRowsData = this.rows;
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
</style>
