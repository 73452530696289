<template>
    <div class="field-table tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-full tw-text-base-content">
        <neo-tab :tabs="filteredTabs" @tabChange="selectTab($event)" margin="0" :active="currentTab" v-if="creditPackSelected"> </neo-tab>
        <vue-good-table ref="table" :isLoading="loading" :columns="computedColumns" :rows="filteredRowsData" :pagination-options="paginationOptions" :key="filteredRowsData">
            <template slot="table-actions">
                <button
                    v-if="Object.values(filters).length"
                    @click="clearFilters"
                    class="tw-bg-transparent tw-border tw-border-solid tw-rounded-2xl tw-px-2 tw-py-1 tw-mr-2 tw-cursor-pointer 
                    tw-border-brand tw-text-brand hover:tw-bg-brand hover:tw-text-white"
                >
                    RESET FILTER
                </button>
                <span v-else></span>
            </template>

            <template #column-filter="{ column }">
                <neo-datepicker v-if="column.field_type === 'date' && column.filterOptions && column.filterOptions.enabled" :range="true" v-model="filters[column.field]" :open-date="new Date()" placeholder="Select Date" format="DD-MMM-YYYY" class="tw-h-10 tw-rounded tw-w-full tw-overflow-hidden" ref="datePicker1" @input="updateColumnFilters()" @clear="clearDateRange(column)"> </neo-datepicker>

                <vue-multiselect ref="setFilterRef" v-else-if="column.filterOptions && column.filterOptions.enabled" placeholder="Select" v-model="filters[column.field]" :options="getFilterOptions(column)" @input="updateColumnFilters()" :multiple="false" :checkboxes="false" :limit="1" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :searchable="true" :show-labels="false" label="label" trackBy="name" class="tw-h-8 tw-w-full"> </vue-multiselect>
            </template>
            <template slot="table-row" slot-scope="props">
                <!-- Column: Activated at -->
                <div v-if="props.column.field === 'activated_at'" class="tw-flex tw-justify-center">
                    <span v-if="props.row[props.column.field]"> Activated on {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }} </span>
                    <span class="tw-text-gray-300 tw-cursor-pointer" v-else-if="dueDatePassed(props.row.activation_due_date)" title="Activation due date has already been over for the credit pack.">
                        Activate
                    </span>
                    <span class="tw-text-brand tw-cursor-pointer" v-else @click="activatePack(props.row)">
                        Activate
                    </span>
                </div>

                <!-- Column: Activation due date -->
                <div v-else-if="props.column.field === 'activation_due_date'" class="tw-flex tw-justify-center">
                    <span v-if="props.row[props.column.field] && !props.row.activated_at">
                        {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }}
                    </span>
                    <span v-else> - </span>
                </div>

                <!-- Column: Users -->
                <div v-else-if="props.column.field === 'users'" class="tw-flex tw-justify-center tw-cursor-pointer" @click="assignedUsers(props.row.users)">
                    <div class="tw-flex">
                        <font-awesome-icon class="tw-w-6 tw-mx-2" icon="user" />
                        <span
                            class="tw-h-4 tw-relative tw--top-2 tw--left-2 
                            tw-rounded tw-rounded-lg tw-border tw-border-blue-600
                            tw-bg-brand tw-text-white tw-px-2"
                        >
                            {{ getActiveUsers(props.row.users).length }}
                        </span>
                    </div>
                </div>

                <!-- Column: Unique Id -->
                <div v-else-if="props.column.field === 'display_name'" @click="selectCreditPack(props.row)" class="tw-flex tw-cursor-pointer tw-text-brand">
                    {{ props.row[props.column.field] }}
                </div>

                <!-- Column: Status -->
                <div v-else-if="props.column.field === 'status'" class="tw-flex tw-justify-center">
                    <span :class="['Allocated', 'Active'].includes(props.row.status) ? 'tw-text-success' : 'tw-text-danger'">
                        {{ props.row.status }}
                    </span>
                </div>

                <!-- Date type field -->
                <div v-else-if="props.column.field_type === 'date'" class="tw-flex tw-justify-center">
                    <span v-if="props.row[props.column.field]">
                        {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }}
                    </span>
                    <span v-else> - </span>
                </div>

                <!-- Default -->
                <span v-else class="tw-flex tw-justify-center"> {{ props.row[props.column.field] }} </span>
            </template>

            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
        <Modal ref="assigned-users" title="Assigned Users" :showFooter="false">
            <template #content>
                <div class="tw-grid tw-gap-2">
                    <neo-input class="tw-rounded-lg tw-h-10" placeholder="Search" bg="#fff" v-model.trim="search" style="border: 1px solid #e8e8e8;" />

                    <span class="tw-border-0 tw-border-solid tw-border-b tw-border-gray-200 tw-text-bold tw-pb-2" v-for="data in filteredUserLists" :key="data.user_id">
                        {{ data.user_name }}
                    </span>
                </div>
            </template>
        </Modal>

        <modalConfirm ref="modal_confirm" />
    </div>
</template>
<script>
import Modal from "@/components/modal-content";
import dropdown from "@/components/dropdown-base";
import NeoInput from "@/components/input";
import Loader from "@/components/loader";
import Tab from "@/components/tab";
const VueMultiselect = () => import("@/components/vue-multiselect");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import modalConfirm from "@/components/modal-confirm";
import {DateTime} from "luxon";
import {getCreditInventory, activateCreditPack, getAllUsers, getUserFullName} from "../services";
import {mapGetters} from "vuex";
import {onlyUnique} from "@/utils/functions.js";

export default {
    name: "inventory",
    title: "Inventory",
    components: {
        Modal,
        dropdown,
        NeoInput,
        Loader,
        "neo-tab": Tab,
        "neo-datepicker": DatePicker,
        VueMultiselect,
        modalConfirm,
    },
    data() {
        return {
            currentTab: "credit-allotted",
            tabs: [
                {
                    key: "credit-allotted",
                    name: "Credit Allocated History",
                    enabled: true,
                    admin: true,
                    analyst: true,
                },
            ],
            columns: [
                {
                    label: "Pack Name",
                    field: "name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Unique Id",
                    field: "display_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Credits",
                    field: "pack_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Assigned User",
                    field: "users",
                },
                {
                    label: "Allocated Date",
                    field: "created_at",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Validity Period",
                    field: "validity",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Action",
                    field: "activated_at",
                    field_type: "date",
                },
                {
                    label: "Activation Due Date",
                    field: "activation_due_date",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Expiry Date",
                    field: "valid_till",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
            ],
            creditUsersColumns: [
                {
                    label: "User",
                    field: "user_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Allocated Credits",
                    field: "issued_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Allocated Date",
                    field: "created_at",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Used Credits",
                    field: "used",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Unused Credits",
                    field: "user_available_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Expiry Date",
                    field: "valid_till",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Status",
                    field: "status",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
            ],
            rows: [],
            loading: false,
            filteredRowsData: [],
            filters: {},
            paginationOptions: {
                enabled: true,
                mode: "records",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
            },
            userLists: [],
            allUsers: [],
            creditPackSelected: null,
            search: "",
        };
    },
    async mounted() {
        this.loading = true;
        await this.fetchData();
        if (this.getAccountId) this.allUsers = await getAllUsers(this.getAccountId);
        this.loading = false;
    },
    watch: {
        async "$route.query"(to, from) {
            if (!to.id) this.creditPackSelected = null;
            this.clearFilters();
        },
    },
    computed: {
        ...mapGetters(["getAccountId"]),
        filteredUserLists() {
            return this.userLists.filter((el) => el.user_name.toLowerCase().includes(this.search.toLowerCase()));
        },

        filteredTabs() {
            return this.tabs;
        },

        computedColumns() {
            const colName = this.creditPackSelected ? "creditUsersColumns" : "columns";
            return this?.[colName];
        },
    },
    methods: {
        selectTab(tab) {
            console.log(tab);
        },

        disabledRange(date) {
            return date > new Date();
        },

        clearDateRange(column) {
            this.filters[column.field] = [];
        },

        dueDatePassed(date) {
            const activation_due_date = DateTime.fromISO(date).endOf("day");
            const curr_date = DateTime.now();
            const diff = activation_due_date.diff(curr_date, ["days"]).toObject();
            if (diff.days <= 0) return true;
            else false;
        },

        async activatePack(row) {
            const current_date = DateTime.now();
            let expiry_date = DateTime.fromISO(current_date).plus({
                days: row.validity,
            });
            expiry_date = DateTime.fromISO(expiry_date).toFormat("dd MMM yyyy");
            const confirm_modal = await this.$refs["modal_confirm"].show({
                title: "Are you sure?",
                message: `Are you sure, you want to activate the credit pack? It will expire on ${expiry_date}.`,
                // message: `Activation will be done on due date, Expiry Date will be ${expiry_date}`
            });
            this.$refs["modal_confirm"].close();
            if (confirm_modal) {
                await activateCreditPack(row.id);
                await this.fetchData();
            }
        },
        async selectCreditPack(row) {
            this.creditPackSelected = row;
            this.$router
                .push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        ...{id: this.creditPackSelected.id},
                    },
                })
                .catch(() => {});
            this.$emit("updateBreadcrumbs");
            await this.fetchData();
        },
        async fetchData() {
            this.rows = [];
            let qParams;
            if (this.getAccountId)
                qParams = {
                    tenant_id: this.getAccountId,
                };
            if (this.creditPackSelected)
                qParams = {
                    ...qParams,
                    ...{credit_pack_id: this.creditPackSelected.id},
                };
            const {data} = await getCreditInventory(qParams);
            if (this.creditPackSelected)
                this.rows = data.data?.[0]?.users.map((el) => ({
                    ...el,
                    ...{
                        user_name: el?.first_name + " " + (el?.last_name || ""),
                        valid_till: this.creditPackSelected.valid_till,
                        status: el?.active ? (el?.balance === 0 ? "Exhausted" : "Allocated") : "Unallotted",
                    },
                }));
            else {
                this.rows = data.data;
            }
            this.filteredRowsData = this.rows;
            // this.pageData = data?.page;
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            dt = dt.substr(0, 10);
            return DateTime.fromISO(dt).toFormat(format);
        },

        getFilterOptions(column) {
            // get unique filter options array
            let filterData = [];
            filterData = this.rows
                .filter((row) => row[column.field])
                .map((row) => ({
                    name: column?.field_type == "date" ? this.formatDateFromISO(row[column.field], "dd MMM yyyy") : row[column.field],
                    label: row[column.field],
                }));
            if (filterData && filterData?.length > 0) {
                const uniqueMap = new Map();
                filterData = filterData.forEach((item) => {
                    uniqueMap.set(item.name, item);
                });
                return Array.from(uniqueMap.values());
            }
            return filterData;
        },
        updateColumnFilters() {
            let result = this.rows;
            for (let filter in this.filters) {
                let type = this.computedColumns.find((col) => col.field === filter)?.field_type;
                if (type === "date" && this.filters[filter]?.length && this.filters[filter][0] && this.filters[filter][1]) {
                    result = result.filter((el) => {
                        const startDate = new Date(this.filters[filter][0]).setHours(0, 0, 0, 0);
                        const endDate = new Date(this.filters[filter][1]).setHours(23, 59, 59);
                        const date = new Date(el[filter]).getTime();
                        return startDate < date && date < endDate;
                    });
                } else if (type !== "date" && this.filters[filter]) {
                    result = result.filter((el) => {
                        return this.filters[filter].name === el[filter];
                    });
                }
            }
            this.filteredRowsData = result;
        },
        clearFilters() {
            this.filters = {};
            this.filteredRowsData = this.rows;
        },

        getActiveUsers(users) {
            return users.filter((el) => el.active).filter(onlyUnique);
        },

        assignedUsers(users) {
            const active_users = this.getActiveUsers(users);
            if (active_users.length) {
                this.userLists = active_users.map((user) => ({
                    ...user,
                    ...{user_name: getUserFullName(user)},
                }));
                this.search = "";
                this.$refs["assigned-users"].showModal();
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
.field-table {
    .tabs {
        display: flex;
    }
}
</style>
