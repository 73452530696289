<template>
    <div class="credit-packs tw-p-2 wide:tw-p-4 tw-text-base-content tw-flex tw-flex-col tw-gap-2 tw-h-full tw-overflow-hidden">
        <neo-tab :tabs="filteredTabs" @tabChange="selectTab($event)" margin="0" :active="currentTab" v-if="!$route.query.id"> </neo-tab>

        <div class="breadcrumbs tw-flex tw-gap-2 tw-items-center">
            <button
                class="tw-rounded tw-rounded-lg tw-border tw-border-blue-600 
                tw-px-4 tw-py-2 tw-text-brand tw-cursor-pointer"
                v-if="$route.query.id"
                @click="back"
            >
                Back
            </button>
            <span class="tw-text-brand tw-flex tw-gap-2 tw-font-semibold" v-for="(route, index) in breadcrumbs" :key="index">
                {{ route }}
                <span class="tw-text-black" v-if="index < breadcrumbs.length - 1"> > </span>
            </span>
        </div>

        <div class="credit-main-container">
            <template v-if="currentTab === 'feature'">
                <Feature ref="table-data" />
            </template>
            <template v-if="currentTab === 'credit-usage'">
                <CreditUsage ref="table-data" />
            </template>
            <template v-if="currentTab === 'inventory'">
                <Inventory ref="table-data" @updateBreadcrumbs="updateBreadcrumbs" />
            </template>
            <template v-if="currentTab === 'credit-packs'">
                <CreditPacks ref="table-data" @updateBreadcrumbs="updateBreadcrumbs" />
            </template>
            <template v-if="currentTab === 'credit-allocation'">
                <CreditAllocation ref="table-data" @updateBreadcrumbs="updateBreadcrumbs" />
            </template>
        </div>
    </div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import {mapGetters} from "vuex";
import Tab from "@/components/tab";
import Feature from "./feature";
import Inventory from "./inventory";
import CreditAllocation from "./credit-allocation";
import CreditPacks from "./credit-pack-list";
import CreditUsage from "./credit-usage";

export default {
    title: "Credit Packs",
    mixins: [titleMixin],
    name: "credit-admin",
    components: {
        "neo-tab": Tab,
        Feature,
        Inventory,
        CreditAllocation,
        CreditPacks,
        CreditUsage,
    },
    data() {
        return {
            currentTab: "feature",
            tabs: [
                {
                    key: "feature",
                    name: "Feature",
                    enabled: true,
                    admin: true,
                    analyst: true,
                },
                {
                    key: "inventory",
                    name: "Inventory",
                    enabled: true,
                    admin: true,
                    analyst: false,
                },
                {
                    key: "credit-allocation",
                    name: "Credit Allocation",
                    enabled: true,
                    admin: true,
                    analyst: false,
                },
                {
                    key: "credit-packs",
                    name: "Credit Pack",
                    enabled: true,
                    admin: false,
                    analyst: true,
                },
                {
                    key: "credit-usage",
                    name: "Credit Usage",
                    enabled: true,
                    admin: true,
                    analyst: true,
                },
            ],
            breadcrumbs: [],
        };
    },
    computed: {
        ...mapGetters(["getIsUserAdmin", "getUserAuthDetails"]),
        filteredTabs() {
            return this.tabs.filter((tab) => {
                return tab.enabled && ((this.getIsUserAdmin && tab.admin) || (!this.getIsUserAdmin && tab.analyst));
            });
        },

        tabName() {
            return (this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)).split("-").join(" ");
        },
    },
    mounted() {
        if (this.getIsUserAdmin) this.breadcrumbs.push("Admin");
        else this.breadcrumbs.push("User");

        this.currentTab = this.$route?.query?.tab || "feature";
        this.breadcrumbs.push(this.tabName);
    },
    methods: {
        selectTab(tab) {
            if (tab !== this.currentTab) {
                this.currentTab = tab;
                this.breadcrumbs.splice(1);
                this.breadcrumbs.push(this.tabName);
                this.$router
                    .push({
                        path: this.$route.path,
                        query: {
                            tab: this.currentTab,
                        },
                    })
                    .catch(() => {});
            }
        },
        updateBreadcrumbs() {
            if (this.currentTab === "credit-allocation") {
                // if (this.$route.query.id === 'NEW')
                this.breadcrumbs.push("Allocate credit pack");
                // else this.breadcrumbs.push("Modify credit pack");
            } else this.breadcrumbs.push(this.$route.query.id);
        },
        back() {
            this.breadcrumbs.splice(2);
            this.$router.push({
                path: this.$route.path,
                query: {
                    tab: this.$route.query.tab,
                },
            });
            this.$refs["table-data"].creditPackSelected = true;
            this.$refs?.["table-data"]?.fetchData();
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
.credit-packs {
    .tabs {
        display: flex;
    }
    .credit-main-container {
        height: calc(100% - 100px);
        overflow-y: auto;
        @include scrollBar;
    }
}
</style>
